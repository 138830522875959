<template>
  <div
    v-if="showTrustbox"
    id="trustpilot-widget"
    class="trustpilot-widget"
    :data-locale="localeIso"
    data-template-id="5419b6ffb0d04a076446a9af"
    data-businessunit-id="61167305ff4b0d002096123f"
    data-style-height="20px"
    data-style-width="100%"
    data-theme="light"
    data-font-family="Noto Sans"
  >
    <a href="https://nl.trustpilot.com/review/villaforyou.com" target="_blank" rel="noopener">Trustpilot</a>
  </div>
</template>

<script setup>
const { locale } = useI18n()
const localeIso = computed(() => {
  switch (locale.value) {
    case 'nl':
      return 'nl-NL'
    case 'de':
      return 'de-DE'
    case 'fr':
      return 'fr-FR'
    default:
      return 'en-US'
  }
})
const showTrustbox = ref(false)
const loadTrustpilotWidget = () => {
  showTrustbox.value = false
  // Delete the existing Trustpilot script (incase loaded)
  const existingScript = document.getElementById('trustpilot-widget-script')
  if (existingScript) {
    existingScript.remove()
  }
  // Add script tag to the body
  const script = document.createElement('script')
  script.id = 'trustpilot-widget-script'
  script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  script.async = true
  document.body.appendChild(script)
  // Wait till script is loaded
  script.onload = () => {
    // Add the html element
    showTrustbox.value = true
    // Load the script with 100 ms delay (because the widget sucks donkeyballs)
    setTimeout(() => {
      window.Trustpilot.loadFromElement(document.getElementById('trustpilot-widget'))
    }, 100)
  }
}
const nuxtApp = useNuxtApp()
nuxtApp.hook('i18n:localeSwitched', () => {
  loadTrustpilotWidget()
})
onMounted(() => {
  loadTrustpilotWidget()
})
</script>

<style lang="postcss" scoped>
.trustpilot-widget{
  @apply bg-white mb-[60px] py-3;
}
</style>
